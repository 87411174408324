<template>
    <div class="random">
        <div class="header-bg"> </div>
        <div class="container">
            <Header />
            <div class="top">
                <slot name="top"></slot>
            </div>
            <div class="bot">
                <loading :rows="16" v-if="loading"></loading>
                <div class="topic" v-if="!loading">
                    <div style="margin: 10px 18px">
                        <div class="answer_card">
                            <div class="title">答题卡</div>

                        </div>

                        <div class="signlist">
                            <div style="display: flex">
                                <div class="title">答对：<span style="color: #00C94C">{{ rightCount }}</span></div>
                                <div class="title" style="margin-left: 50px">答错：<span style="color: #FF3B30">{{ errorCount
                                }}</span></div>
                            </div>
                            <div class="listbox">
                                <div class="item" @click="handelClick(index)" v-for="(item, index) in answerList"
                                    :key="index"
                                    :class="[item.selectAnswer && item.selectAnswer.length > 0 && rememberMode !== '1' ? (item.selectAnswer === item.rightAnswer) ? 'yes' : 'error' : 'no', index === idx ? 'new' : '']">
                                    {{ index + 1 }}
                                    <div class="current">当前</div>
                                </div>
                            </div>
                        </div>
                        <div class="setting">

                            <div class="title">设置</div>

                            <div class="item1">
                                <div>答对自动下一题</div>
                                <el-switch v-model="autoNext" active-color="#00C94C" inactive-value="0" active-value="1"
                                    @change="autoNextSetting"></el-switch>
                            </div>
                            <div class="item2">
                                <div>背题模式</div>
                                <el-switch v-model="rememberMode" active-color="#00C94C" inactive-value="0" active-value="1"
                                    @change="rememberModeSetting"></el-switch>
                            </div>
                            <div class="item2" v-if="showAutoRemove">
                                <div>答对自动移除错题</div>
                                <el-switch v-model="autoRemove" active-color="#00C94C" inactive-value="0" active-value="1"
                                    @change="autoRemoveSetting"></el-switch>
                            </div>

                        </div>
                    </div>
                </div>
                <div class="right" v-if="!loading">
                    <div class="subject">
                        <empty v-if="empty" />
                        <div style="display: flex;flex-direction: column;height: 100%;" v-else>
                            <div style="flex: 1;">
                                <div class="titlebox">
                                    <div class="title">{{ type }}题</div>
                                    <div class="title_pub">{{ currentQuestion.type !== 1 ? '只能选择唯一答案' : '可以选择多个答案' }}</div>
                                </div>
                                <div class="exercises">
                                    <div class="type">{{ type }}题</div>
                                    <div class="title">{{ idx + 1 }}/{{ total }} <span
                                            v-html="currentQuestion.title"></span>
                                    </div>
                                </div>
                                <div class="radiobox">

                                    <!-- 单选题 -->
                                    <el-radio v-show="(currentQuestion.type !== 1)"
                                        v-for="item in (currentQuestion.options || [])" :key="item.title" class="radio"
                                        v-model="radio"
                                        :disabled="(currentQuestion.selectAnswer && currentQuestion.selectAnswer.length > 0) || checking||readMode"
                                        :label="item.key">
                                        <div style="display: flex;align-items: center">

                                            <div
                                                v-if="[currentQuestion.rightAnswer, currentQuestion.selectAnswer].indexOf(item.key) > -1">
                                                <img style="width: 33px;height: 33px;flex-shrink: 0"
                                                    :src="item.key === currentQuestion.rightAnswer ? require('../assets/img/success.png') : require('../assets/img/error.png')" />
                                            </div>
                                            <div v-else
                                                style="flex-shrink: 0;width: 32px;height: 32px;border: 1px solid #E6E6E6;border-radius: 32px;text-align: center;line-height: 32px;cursor: pointer">
                                                {{ item.key }}</div>

                                            <div
                                                v-if="[currentQuestion.rightAnswer, currentQuestion.selectAnswer].indexOf(item.key) > -1">

                                                <div class="label success" v-if="currentQuestion.rightAnswer === item.key"
                                                    v-html="item.value">

                                                </div>
                                                <div class="label error" v-else v-html="item.value">

                                                </div>

                                            </div>
                                            <div class="label" v-else v-html="item.value">

                                            </div>

                                        </div>

                                    </el-radio>
                                    <!-- 多选题 -->
                                    <el-checkbox v-show="(currentQuestion.type === 1)"
                                        v-for="(item, index) in currentQuestion.options || []" :key="index" class="radio"
                                        v-model="checked"
                                        :disabled="(currentQuestion.selectAnswer && currentQuestion.selectAnswer.length > 0) || checking||readMode"
                                        :label="item.key">
                                        <div style="display: flex;align-items: center">

                                            <div
                                                v-if="currentQuestion.selectAnswer && currentQuestion.selectAnswer.indexOf(item.key) !== -1">
                                                <img style="width: 33px;height: 33px;flex-shrink: 0"
                                                    :src="currentQuestion.selectAnswer === currentQuestion.rightAnswer ? require('../assets/img/success.png') : require('../assets/img/error.png')" />
                                            </div>
                                            <div v-else class="myCheckbox"
                                                :class="checked.indexOf(item.key) !== -1 ? 'checked' : ''">{{ item.key }}
                                            </div>

                                            <div
                                                v-if="currentQuestion.selectAnswer && currentQuestion.selectAnswer.indexOf(item.key) !== -1">

                                                <div class="label success"
                                                    v-if="currentQuestion.selectAnswer === currentQuestion.rightAnswer"
                                                    v-html="item.value">


                                                </div>
                                                <div class="label error" v-else v-html="item.value">

                                                </div>

                                            </div>
                                            <div class="label" v-else v-html="item.value">
                                            </div>

                                        </div>

                                    </el-checkbox>

                                </div>


                                <div class="btn">

                                    <div v-if="!readMode">
                                        <button class="submit"
                                            v-if="currentQuestion.type === 1 && !checking && !currentQuestion.selectAnswer"
                                            @click="multipleAnswer">提交答案</button>
                                    </div>
                                    <div
                                        style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px">
                                        <div>
                                            <button class="up" @click="handelUp">上一题</button>
                                            <button class="go" @click="handelGo">下一题</button>
                                        </div>
                                        <div style="display: flex;">
                                            <div style="display: flex;align-items: center;cursor: pointer"
                                                @click="onCollect">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="isCollect ? require('../assets/img/like.png') : require('../assets/img/dislike.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">
                                                    {{ isCollect ? '已收藏' : '收藏' }}</div>
                                            </div>
                                            <div style="margin-left: 16px;display: flex;align-items: center;cursor: pointer"
                                                @click="handleFeeback">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="require('../assets/img/error_feedback.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">纠错</div>
                                            </div>
                                            <div style="margin-left: 16px;display: flex;align-items: center;cursor: pointer"
                                                @click="showNote = true">
                                                <img style="vertical-align: middle;width: 24px;height: 24px"
                                                    :src="require('../assets/img/note.png')" />
                                                <div style="color: rgba(60,60,67,0.6);font-size: 15px;">笔记</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style="flex-shrink: 0;">
                                <div class="ansbox" v-if="currentQuestion.rightAnswer">
                                    <div class="answer">正确答案：<span class="active">{{ currentQuestion.rightAnswer }}</span>
                                    </div>
                                    <div v-if="currentQuestion.selectAnswer&&rememberMode!=='1'" style="margin-left: 100px;" class="answer">我的答案：<span
                                            :style="currentQuestion.rightAnswer == currentQuestion.selectAnswer ? 'color: #00C94C;' : 'color: #FF3B30;'">{{
                                                currentQuestion.selectAnswer }}</span>
                                        <img style="vertical-align: middle;width: 24px;height: 24px;margin-left: 20px;"
                                            :src="currentQuestion.rightAnswer == currentQuestion.selectAnswer ? require('../assets/img/my_answer_right.png') : require('../assets/img/my_answer_error.png')">
                                    </div>
                                </div>
                                <!--解析-->
                                <div class="parse" v-if="currentQuestion.rightAnswer">
                                    <div class="title">试题解析</div>
                                    <div class="desc" v-html="currentQuestion.analysis || '暂无解析'">
                                    </div>

                                </div>
                                <!--笔记-->
                                <div class="note" v-if="showNote||readMode">
                                    <div style="padding: 16px;">
                                        <el-input v-model="currentQuestion.note" type="textarea" autosize
                                            placeholder="请输入学习笔记"></el-input>
                                        <div style="text-align: right;margin-top: 5px;">
                                            <el-button size="small" type="primary" :loading="noteBtnLoading"
                                                @click="submitNote">保 存</el-button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                </div>
            </div>
        </div>


        <el-dialog title="试题纠错" :visible.sync="showFeedbckDialog" width="32%" :close-on-click-modal="false"
            @open="open">
            <div>
                <el-form label-position="top" ref="feedbackForm" :model="feedbackForm" :rules="feedbackFormRules">
                    <el-form-item label="问题类型" prop="type">
                        <el-radio-group v-model="feedbackForm.type">
                            <el-radio v-for="(item, index) in feedbackQuestionTypeOptions" :key="index"
                                :label="item.value">{{ item.label }}</el-radio>
                        </el-radio-group>
                    </el-form-item>
                    <el-form-item label="具体描述" prop="refer">
                        <el-input v-model="feedbackForm.refer" type="textarea" rows="5"
                            placeholder="欢迎指出具体问题，并提供正确的描述，您的耐心指点是我们前进的动力。"></el-input>
                    </el-form-item>
                    <el-form-item label="上传图片">
                        <image-upload v-model="feedbackForm.imageUrl" :limit="9" :isShowTip="false"></image-upload>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" :loading="feedbackBtnLoading" @click="submitFeedback('feedbackForm')">提
                    交</el-button>
                <el-button @click="showFeedbckDialog = false">取 消</el-button>

            </span>
        </el-dialog>

        <Footer />

    </div>
</template>

<script>
import Header from "../components/Header.vue"
import Footer from "../components/Footer.vue"
import Empty from '../components/Empty'
import Loading from '@/components/Loading'
import ImageUpload from '@/components/ImageUpload'
import { GetPracticeQuestionList, GetChapterQuestList, SubmitAnswer, CollectQuest, GetQuestCollectById, SavePracticeRecord, SaveQuestionFeedback, SaveQuestionNote } from "../api/text";
import { GetDict } from '@/api/list'
import { formatDateTime } from '@/utils/tools'

export default {
    props: {
        //接口url
        url: {
            type: String,
            default: ''
        },
        //显示答对自动移除错题
        showAutoRemove: {
            type: Boolean,
            default: false
        },
        //只读模式
        readMode:{
            type:Boolean,
            default:false
        }

    },
    data() {
        return {
            startTime: '',
            endTime: '',
            type: '单选',
            idx: 0,
            isOver: false,
            radio: null,
            checked: [],
            rightCount: 0,
            errorCount: 0,
            loading: true,
            empty: false,
            total: 0,
            page: 1,
            pageSize: 20,
            cacheMap: {},
            questionList: [],
            currentQuestion: {},
            answerList: [],
            isCollect: false,
            checking: false,
            showFeedbckDialog: false,
            feedbackForm: {
                subjectId: null,
                type: '',
                refer: '',
                imageUrl: [],

            },
            feedbackQuestionTypeOptions: [],
            feedbackBtnLoading: false,
            autoNext: this.$store.state.user.autoNext,
            rememberMode: this.$store.state.user.rememberMode,
            autoRemove:this.$store.state.user.autoRemove,
            showNote: false,
            noteBtnLoading: false,

            feedbackFormRules: {
                type: [
                    { required: true, message: '请选择问题类型', trigger: 'change' },
                ],
                refer: [
                    { required: true, message: '请输入问题描述', trigger: 'blur' },
                ],

            }
        }
    },
    components: {
        Header,
        Footer,
        Empty,
        ImageUpload,
        Loading,
        ImageUpload
    },
    watch: {

        radio(newVal) {

            if (newVal.length > 0) {

                this.submitAnswer(newVal)
            }
        }
    },
    created() {

        if(this.$route.query.idx){

            try{
                this.idx = parseInt(this.$route.query.idx) 
            }catch(e){
                this.idx =0;
            }
        
        }

        this.startTime = formatDateTime(new Date());
        //监听科目改变
        this.bus.$on(this.ON_CATEGORY_CHANGE, () => {

            this.reset();
        })

        this.getQuestList();

    },
    

    methods: {
        //加载试题
        getQuestList() {
            this.page = Math.ceil((this.idx + 1) / this.pageSize)
            if (this.cacheMap[this.page]) {
                this.questionList = this.cacheMap[this.page]
                if (this.questionList.length > 0) {
                    this.initCurrentQuestion()
                }
                return;
            }
             //章节练习
            if (this.$route.query.chapterId) {
                GetChapterQuestList(this.$route.query.chapterId, this.page, this.pageSize).then(res => {
                    this.cacheMap[this.page] = res.data
                    this.questionList = res.data;
                    this.empty = this.questionList.length === 0
                    if (this.total === 0) {
                        this.total = res.total

                        for (let i = 0; i < this.total; i++) {

                            this.answerList.push({ selectAnswer: '', rightAnswer: '', analysis: '' })

                        }
                    }
                    if (this.questionList.length > 0) {
                        this.initCurrentQuestion()
                    }

                }).finally(() => {
                    this.loading = false;
                })
            } else {
                //其他练习
                GetPracticeQuestionList(this.url, this.$store.state.user.category.id, this.page, this.pageSize).then(res => {

                    this.cacheMap[this.page] = res.data

                    this.questionList = res.data
                    if (this.total === 0) {
                        this.total = res.total

                        for (let i = 0; i < this.total; i++) {

                            this.answerList.push({ selectAnswer: '', rightAnswer: '', analysis: '' })

                        }
                    }
                    if (this.questionList.length > 0) {
                        this.initCurrentQuestion()
                    }
                }).finally(() => {
                    this.loading = false
                })
            }

        },
        //重置答题
        reset() {
            this.loading = true;
            this.rightCount = 0;
            this.errorCount = 0;
            this.idx = 0;
            this.currentQuestion = {}
            this.answerList = []
            this.cacheMap = {}
            this.total = 0;
            this.getQuestList()
        },

        //初始化当前试题
        initCurrentQuestion() {

            let i = this.idx % this.pageSize
            this.currentQuestion = this.questionList[i];
            this.checkCollect();
            this.type = this.formatType(this.currentQuestion.type)
            this.radio = '';
            this.checked = [];
            this.showNote=false;
            //开启背题模式
            if (this.rememberMode === '1') {
                this.submitAnswer('')
            }
        },
        //点击题目编号
        handelClick(index) {
            this.idx = index;
            this.getQuestList()

        },
        //自动下一题设置
        autoNextSetting(value) {
            this.$store.commit('user/saveAutoNext', value)

        },
        //背题模式设置
        rememberModeSetting(value) {
            this.$store.commit('user/saveRememberMode', value);
            this.initCurrentQuestion();
        },
        //答对自动移除错题
        autoRemoveSetting(value) {

            this.$store.commit('user/saveAutoRemove', value)

        },
        //格式化题型
        formatType(type) {

            switch (type) {

                case 1:
                    return '多选';
                case 2:
                    return '判断';

                case 3:
                    return '单选';

                default: return '单选';
            }
        },

        //多选题作答
        multipleAnswer() {

            if (this.checked.length < 2) {

                this.$message.error('至少选择两个答案')
                return
            }

            this.submitAnswer(this.checked.join(''))

        },
        //提交答案
        submitAnswer(answer) {

            this.checking = true;
            SubmitAnswer({
                answer: answer,
                subjectId: this.currentQuestion.id,
                categoryId: this.currentQuestion.questionCategory,
                status: this.autoRemove,
                accountId: this.$store.state.user.category.id
            }).then(res => {

                this.answerList[this.idx].selectAnswer = this.rememberMode === '1' ? res.data.rightAnswer : answer;
                this.answerList[this.idx].rightAnswer = res.data.rightAnswer;
                this.answerList[this.idx].analysis = res.data.analysis;

                let i = this.idx % this.pageSize
                this.questionList[i].selectAnswer = this.rememberMode === '1' ? res.data.rightAnswer : answer;
                this.questionList[i].rightAnswer = res.data.rightAnswer;
                this.questionList[i].analysis = res.data.analysis;
                this.currentQuestion = this.questionList[i];
                this.showNote = this.currentQuestion.note && this.currentQuestion.note.length > 0

                if (this.rememberMode === '0') {
                    this.rightCount = this.answerList.filter(item => item.selectAnswer && item.selectAnswer === item.rightAnswer).length;

                    this.errorCount = this.answerList.filter(item => item.selectAnswer && item.selectAnswer !== item.rightAnswer).length;

                    if (this.autoNext === '1' && res.data.status === 0) {

                        this.handelGo();

                    }

                    //所有题目已答完
                    if (this.answerList.length === this.answerList.filter(item => item.selectAnswer).length) {

                        this.$confirm('已完成答题,请选择操作?', '提示', {
                            confirmButtonText: '继续答题',
                            cancelButtonText: '返回',
                            type: 'success'
                        }).then(() => {

                            this.reset()


                        }).catch(() => {
                            this.$router.back()
                        })
                    }
                }


            }).finally(() => {
                this.checking = false;
            })

        },
        //上一题
        handelUp() {

            if (this.idx > 0) {
                this.idx--;
                this.getQuestList()
            }

        },

        //下一题
        handelGo() {

            if (this.idx < this.answerList.length - 1) {

                this.idx++;
                this.getQuestList()

            }

        },

        //收藏
        onCollect() {

            CollectQuest({
                subjectId: this.currentQuestion.id,
                accountId: this.$store.state.user.category.id
            }).then(() => {
                this.isCollect = !this.isCollect;
            })
        },
        //检查是否收藏
        checkCollect() {

            GetQuestCollectById(this.currentQuestion.id).then(res => {

                this.isCollect = res.data;

            })
        },
        //题目纠错
        handleFeeback() {

            this.feedbackForm = {
                subjectId: this.currentQuestion.id,
                type: '',
                refer: '',
                imageUrl: [],

            }

            //加载字典
            GetDict('problem_type').then(res => {

                this.feedbackQuestionTypeOptions = res.data
            })

            this.showFeedbckDialog = true;


        },

        //重置dialog表单
        open(){
             this.$nextTick(()=>{
                 this.$refs.feedbackForm.resetFields()
             })

        },

        //提交纠错
        submitFeedback(formName) {

            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.feedbackBtnLoading = true;
                    let form = Object.assign({}, this.feedbackForm)
                    form.imageUrl = this.feedbackForm.imageUrl.split(',')
                    SaveQuestionFeedback(form).then(() => {

                        this.showFeedbckDialog = false;
                        this.$message.success('提交成功')

                    }).finally(() => {
                        this.feedbackBtnLoading = false;
                    })

                }

            })

        },
        //保存笔记
        submitNote() {

            if (!this.currentQuestion.note || this.currentQuestion.note.length === 0) {

                this.$message.error('请输入笔记内容')

                return
            }

            this.noteBtnLoading = true;
            SaveQuestionNote({ type: this.currentQuestion.type, subjectId: this.currentQuestion.id, accountId: this.$store.state.user.category.id, context: this.currentQuestion.note }).then(() => {

                this.$message.success('笔记保存成功')

            }).finally(() => {
                this.noteBtnLoading = false
            })

        }

    },


    //保存练习记录
    beforeDestroy() {
        //解绑
        this.bus.$off(this.ON_CATEGORY_CHANGE)
        this.endTime = formatDateTime(new Date());
        SavePracticeRecord({ accountId: this.$store.state.user.category.id, startTime: this.startTime, endTime: this.endTime })

    }

}
</script>

<style lang="less" scoped>
@import url(../assets/css/RandomPractice.css);


/deep/ .title img{
 
 vertical-align: top;
}
.note {
    /deep/ .el-textarea__inner {

        background-color: transparent !important;
        color: #000;
        border: none;
        resize: none;
    }
}

.top {
    /deep/ .el-breadcrumb__inner {
        color: #fff;

        &:hover {
            color: #fff;
        }
    }
}

.radio {

    .label {
        margin-left: 10px;
        color: #000;
        white-space: normal;
        word-wrap: break-word;
        word-break: break-all;
        line-height: 22px;

        &:hover {
            color: #3B89FF;
        }
    }

    .success {

        color: #00C94C;
    }

    .error {
        color: #FF3B30;
    }

}
</style>
